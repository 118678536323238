import {
  GenericInvocationBuilder,
  InvocationBuilderResult,
  SwapNeoUsingNeoProxyArgs,
} from '@/model/wallets/invocationsBuilder/GenericInvocationBuilder'
import {EnvHelper} from '@/helpers/EnvHelper'
import {tx} from '@cityofzion/neon-core'
import {$} from '@/facade'
import {
  SwapServiceSwapToReceiveArgs,
  SwapServiceSwapToUseArgs,
} from '@cityofzion/blockchain-service'
import {u} from '@cityofzion/neon-js'
import {
  BSNeo3NetworkId,
  FlamingoSwapHelper,
  FlamingoSwapConstants,
} from '@cityofzion/bs-neo3'
import {NeolineInvokeArguments} from '@/model/wallets/types/NeolineTypes'
import {NeoHelper} from '@/helpers/NeoHelper'

type TransferArgs = {
  address: string
  amountToUse: string
  tokenToUseScriptHash: string
  contractScriptHash: string
}

export class NeolineInvocationBuilder implements GenericInvocationBuilder {
  voteInvocation(candidatePublicKey: string): InvocationBuilderResult {
    return {
      invokeArgs: [
        {
          operation: 'vote',
          scriptHash: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
          args: [
            {
              type: 'Hash160',
              value: $.walletAdapter.address!,
            },
            {
              type: 'PublicKey',
              value: candidatePublicKey,
            },
          ],
        },
      ],
      fee: '0',
      signers: [
        {
          account: NeoHelper.getScriptHashFromAddress($.walletAdapter.address!),
          scopes: tx.WitnessScope.CalledByEntry,
        },
      ],
    }
  }

  swapInvocation(
    data:
      | SwapServiceSwapToReceiveArgs<BSNeo3NetworkId>
      | SwapServiceSwapToUseArgs<BSNeo3NetworkId>
  ): InvocationBuilderResult {
    return data.type === 'swapTokenToReceive'
      ? this.swapTokenToReceiveForTokenToUseInvocation(data)
      : this.swapTokenToUseForTokenToReceiveInvocation(data)
  }

  swapNeoUsingNeoProxyInvocation({
    address,
    neoAmountIn,
    gasAmountOutMin,
  }: SwapNeoUsingNeoProxyArgs): InvocationBuilderResult {
    return {
      invokeArgs: [
        {
          scriptHash: EnvHelper.VUE_APP_NEO_PROXY_SCRIPT_HASH,
          operation: 'swapNeo',
          args: [
            {
              type: 'Hash160',
              value: address,
            },
            {
              type: 'Integer',
              value: neoAmountIn,
            },
            {
              type: 'Integer',
              value: gasAmountOutMin,
            },
          ],
        },
      ],
      /**
       * CU-86drvn85a: Investigate what is NeoLine's broadcastOverride and how to where to implement it on NeonDappkit
       *
       * Issue Comment: "The error was the amount of network fee  we were using. The network fee  can be wrong when you end up calling a verify.
       *                 To circumvent this problem you can add a hardcoded networkFeeOverride: 10000000(0.1 gas) on the ContractInvocationMulti."
       */
      networkFeeOverride: 10000000,
      signers: [
        {
          account: EnvHelper.VUE_APP_NEO_PROXY_SCRIPT_HASH,
          scopes: tx.WitnessScope.Global,
        },
        {
          account: NeoHelper.getScriptHashFromAddress(address),
          scopes: tx.WitnessScope.Global,
          allowedContracts: [
            EnvHelper.VUE_APP_NEO_PROXY_SCRIPT_HASH,
            NeoHelper.getScriptHashFromAddress(address),
          ],
        },
      ],
    }
  }

  private swapTokenToReceiveForTokenToUseInvocation({
    address,
    amountToReceive,
    maximumSelling,
    deadline,
    network,
    routePath,
  }: SwapServiceSwapToReceiveArgs<BSNeo3NetworkId>): InvocationBuilderResult {
    const invokeArgs: NeolineInvokeArguments[] = []
    const allowedContracts: string[] = []

    const tokenToReceive = routePath[0]
    const tokenToUse = routePath[routePath.length - 1]

    const tokenToReceiveOverrode = FlamingoSwapHelper.overrideToken(
      network,
      tokenToReceive
    )
    const amountToReceiveFormatted = u.BigInteger.fromDecimal(
      Number(amountToReceive),
      tokenToReceiveOverrode.decimals
    ).toString()
    const maximumSellingFormatted = u.BigInteger.fromDecimal(
      Number(maximumSelling),
      tokenToUse.decimals
    ).toString()

    invokeArgs.push({
      scriptHash: EnvHelper.VUE_APP_FLAMINGO_ROUTER_SCRIPT_HASH,
      operation: 'swapTokenOutForTokenIn',
      args: [
        {
          type: 'Hash160',
          value: address,
        },
        {
          type: 'Integer',
          value: amountToReceiveFormatted,
        },
        {
          type: 'Integer',
          value: maximumSellingFormatted,
        },
        {
          type: 'Array',
          value: [
            {
              type: 'Hash160',
              value: tokenToUse.hash,
            },
            {
              type: 'Hash160',
              value: tokenToReceiveOverrode.hash,
            },
          ],
        },
        {
          type: 'Integer',
          value: deadline,
        },
      ],
    })

    const isNeoSwapped =
      tokenToReceive.hash === EnvHelper.VUE_APP_NEO_SCRIPT_HASH
    if (isNeoSwapped) {
      const amountToUseInTransferFormatted = u.BigInteger.fromNumber(
        Number(amountToReceiveFormatted) * FlamingoSwapConstants.GAS_PER_NEO
      ).toString()
      const transferContractInvocation = this.transferContractInvocation({
        address,
        amountToUse: amountToUseInTransferFormatted,
        tokenToUseScriptHash: EnvHelper.VUE_APP_BNEO_SCRIPT_HASH,
        contractScriptHash: EnvHelper.VUE_APP_GAS_SCRIPT_HASH,
      })

      invokeArgs.push(transferContractInvocation)

      allowedContracts.push(...this.allowedContractsTransfer())
    }

    allowedContracts.push(
      ...this.allowedContractsSwap(tokenToReceive.hash, tokenToUse.hash)
    )

    return {
      invokeArgs,
      fee: '0',
      signers: [
        {
          account: NeoHelper.getScriptHashFromAddress($.walletAdapter.address!),
          scopes: tx.WitnessScope.CustomContracts,
          allowedContracts,
        },
      ],
    }
  }

  private swapTokenToUseForTokenToReceiveInvocation({
    address,
    amountToUse,
    deadline,
    minimumReceived,
    network,
    routePath,
  }: SwapServiceSwapToUseArgs<BSNeo3NetworkId>): InvocationBuilderResult {
    const invokeArgs: NeolineInvokeArguments[] = []
    const allowedContracts: string[] = []

    const tokenToUse = routePath[0]
    const tokenToReceive = routePath[routePath.length - 1]

    const isNeoSwapped = tokenToUse.hash === EnvHelper.VUE_APP_NEO_SCRIPT_HASH
    if (isNeoSwapped) {
      const transferContractInvocation = this.transferContractInvocation({
        address,
        amountToUse,
        tokenToUseScriptHash: EnvHelper.VUE_APP_BNEO_SCRIPT_HASH,
        contractScriptHash: EnvHelper.VUE_APP_NEO_SCRIPT_HASH,
      })

      invokeArgs.push(transferContractInvocation)

      allowedContracts.push(...this.allowedContractsTransfer())
    }

    const tokenToUseOverrode = FlamingoSwapHelper.overrideToken(
      network,
      tokenToUse
    )

    const amountToUseFormatted = u.BigInteger.fromDecimal(
      Number(amountToUse),
      tokenToUseOverrode.decimals
    ).toString()

    const minimumReceivedFormatted = u.BigInteger.fromDecimal(
      Number(minimumReceived),
      tokenToReceive.decimals
    ).toString()

    invokeArgs.push({
      scriptHash: EnvHelper.VUE_APP_FLAMINGO_ROUTER_SCRIPT_HASH,
      operation: 'swapTokenInForTokenOut',
      args: [
        {
          type: 'Hash160',
          value: address,
        },
        {
          type: 'Integer',
          value: amountToUseFormatted,
        },
        {
          type: 'Integer',
          value: minimumReceivedFormatted,
        },
        {
          type: 'Array',
          value: [
            {
              type: 'Hash160',
              value: tokenToUseOverrode.hash,
            },
            {
              type: 'Hash160',
              value: tokenToReceive.hash,
            },
          ],
        },
        {
          type: 'Integer',
          value: deadline,
        },
      ],
    })

    allowedContracts.push(
      ...this.allowedContractsSwap(tokenToReceive.hash, tokenToUse.hash)
    )

    return {
      invokeArgs,
      fee: '0',
      signers: [
        {
          account: NeoHelper.getScriptHashFromAddress($.walletAdapter.address!),
          scopes: tx.WitnessScope.CustomContracts,
          allowedContracts,
        },
      ],
    }
  }

  private transferContractInvocation({
    address,
    amountToUse,
    contractScriptHash,
    tokenToUseScriptHash,
  }: TransferArgs): NeolineInvokeArguments {
    return {
      scriptHash: contractScriptHash,
      operation: 'transfer',
      args: [
        {
          type: 'Address',
          value: address,
        },
        {
          type: 'Hash160',
          value: tokenToUseScriptHash,
        },
        {
          type: 'Integer',
          value: amountToUse,
        },
        {
          type: 'Any',
          value: null,
        },
      ],
    }
  }

  private allowedContractsSwap(
    tokenInScriptHash: string,
    tokenOutScriptHash: string
  ): string[] {
    return [
      EnvHelper.VUE_APP_FLAMINGO_ROUTER_SCRIPT_HASH,
      EnvHelper.VUE_APP_FLAMINGO_FACTORY_SCRIPT_HASH,
      EnvHelper.VUE_APP_FLAMINGO_PAIR_WHITELIST_SCRIPT_HASH,
      tokenInScriptHash,
      tokenOutScriptHash,
    ]
  }

  private allowedContractsTransfer(): string[] {
    return [
      EnvHelper.VUE_APP_GAS_SCRIPT_HASH,
      EnvHelper.VUE_APP_FLP_BNEO_GAS_PAIR_SCRIPT_HASH,
      EnvHelper.VUE_APP_BNEO_SCRIPT_HASH,
    ]
  }
}
